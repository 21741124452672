<template>
  <vs-sidebar click-not-close position-right parent="#app" class="user-sidebar" spacer v-model="localActiveSidebar">
    <div class="mt-6 flex items-center justify-between px-6" slot="header">
      <h4 class="text-white"> {{ this.$t('Ver Información Cliente') }} </h4>
      <feather-icon icon="XIcon" @click.stop="localActiveSidebar = false" class="cursor-pointer"></feather-icon>
    </div>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new pl-6 pr-6 pb-10 pt-4">
      <form id="userForm">
        <h4 class="text-info mb-1">{{ this.$t('Información Básica') }}</h4>
        <vs-divider />
        <div class="vx-row mt-4">
          <!-- codigo -->
          <div class="vx-col w-1/2 mt-2">
            <label for="name"><b>{{ this.$t('ID. Cliente') }}</b></label>
            <vx-input-group>
              <vs-input id="name" name="name" v-model="user.id" val-icon-danger="clear" autofocus readonly="true"
                class="disable"/>
            </vx-input-group>
          </div>
          <!-- nombres -->
          <div class="vx-col w-1/2 mt-2">
            <label for="name"><b>{{ this.$t('Nombres') }}</b></label>
            <vx-input-group>
              <vs-input id="name" name="name" v-model="user.name" val-icon-danger="clear" autofocus readonly="true"
                 />
            </vx-input-group>
          </div>

          <div class="vx-col w-full mt-2">
            <label for="address"><b>{{ this.$t('Direccion') }}</b></label>
            <div class="flex" v-if="user.addresses">
              <vs-input id="address" name="address" class="w-full" val-icon-danger="clear"
                v-model="user.addresses[0].address" readonly />
            </div>
          </div>

          <div class="vx-col w-full mt-2">
            <label for="address"><b>{{ this.$t('Industrial') }}</b></label>
            <div class="flex" v-if="user.seleciona_entrega">
              <vs-input id="address" name="address" class="w-full" val-icon-danger="clear"
                v-model="user.seleciona_entrega" readonly />
            </div>
          </div>

          <div class="vx-col w-full mt-2">
            <label for="address"><b>{{ this.$t('Estado') }}</b></label>
            <div class="flex">
              <div v-if="user.status == 'A'" style="background: #841910; color: #ffffff;"
                class="w-24 p-1 mb-1 rounded-lg text-center">
                {{ $t('Activo') }}
              </div>
              <div v-if="user.status != 'A'" style="background: #dee2e5; color: black;"
                class="w-24 p-1 mb-1 rounded-lg text-center">
                {{ $t('Inactivo') }}
              </div>
            </div>
          </div>

          <div class="vx-col w-1/2 mt-2">
            <label for="name"><b>{{ this.$t('Grupo de precios') }}</b></label>
            <vx-input-group>
              <vs-input id="name" name="name" v-model="user.price_group" val-icon-danger="clear" autofocus readonly="true"
                required />
            </vx-input-group>
          </div>

          <div class="vx-col w-1/2 mt-2">
            <label for="name"><b>{{ this.$t('Nombre Metodo de pago') }}</b></label>
            <vx-input-group>
              <vs-input v-if="user.default_payment_option" id="name" name="name"
                v-model="user.default_payment_option.payment_method_name" val-icon-danger="clear" autofocus
                readonly="true" />
            </vx-input-group>
          </div>

          <div class="vx-col w-2/3 mt-2">
            <label for="address" class="mt-2"><b>{{ this.$t('Consumidores') }}</b></label>
            <ul class="list">
              <li class="list__item" v-for="(item, index) in user.cunsumers_email" :key="index">
                  <feather-icon :icon="'UserIcon'" class="w-5 h-5 mr-1"></feather-icon><span style="line-break: anywhere; white-space: normal; min-width: 100%;" v-html="item"></span>
              </li>
            </ul>
          </div>

          <div class="vx-col w-1/3 mt-2">
            <label for="address" class="mt-2"><b>{{ this.$t('Métodos de pago') }}</b></label>
            <ul class="list">
              <li class="list__item" v-for="(item, index) in user.payment_method_groups" :key="index">
                  <feather-icon :icon="'TargetIcon'" class="w-5 h-5 mr-1"></feather-icon><span style="line-break: anywhere; white-space: normal; min-width: 100%;" v-html="item"></span>
              </li>
            </ul>
          </div>

          <div class="vx-col w-1/2 mt-2">
            <label for="address" class="mt-2"><b>{{ this.$t('Correo Contacto') }}</b></label>
            <ul class="list">
              <li class="list__item" v-for="(item, index) in user.contacts" :key="index">
                  <feather-icon :icon="'UserIcon'" class="w-5 h-5 mr-1"></feather-icon><span style="line-break: anywhere; white-space: normal; min-width: 100%;" v-html="item.email"></span>
              </li>
            </ul>
          </div>
        </div>
        <vs-divider class="my-2" />
      </form>
    </VuePerfectScrollbar>

    <div class=" items-center p-6 mb-10" slot="footer">
      <button type="button" class="vs-component vs-button vs-button-danger vs-button-border vs-button-filled float-right"
        @click="localActiveSidebar = false">
        Volver
      </button>

    </div>
  </vs-sidebar>
</template>

<script lang="ts">
import Vue                 from 'vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect             from 'vue-select';

import { Validation }      from '../../../plugins/validation.js';
import AuthService         from '../../../auth/authService.js';
import Component from 'vue-class-component';

Component.registerHooks([
  'created'
]);

export default Vue.extend({
    props: {
        sidebar : {
            required : true,
            type     : Boolean,
        },
        data    : { required : true },
    },

    data() {
        return {
            validate     : new Validation(),
            validFormats : ['jpg', 'jpeg', 'png'],
            image        : {
                progress : 0,
                error    : '',
                file     : null,
            },
            select_all_stores : false,
            edit        : true,
            dat         : {},
            isVisiblePassword  : false,
            contacto    : ''
        }
    },

    created() {
        /*  this.user.name = this.data.name
        this.user.idetification = this.data.idetification, 
        this.user.email = this.data.email, 
        this.user.password = this.data.password, 
        this.user.id = this.data.id,
        this.user.address = this.data.address */
    },

    watch: {
        'data.email'(val) {
            if (val){
                this.validate.validateEmail( val );
            }
        },
        'data.password'(val) {
            if (val){
                this.validate.validatePassword( val );
            }
        }
    },
        
    methods: {
        getUser(dat){
            this.dat.name = dat.name
            this.dat.idetification = this.data.idetification, 
            this.dat.email = this.data.email, 
            this.dat.password = this.data.password, 
            this.dat.id = this.data.id,
            this.dat.address = this.data.address
        }
        
    },

    computed: {
        todos () {
            return [ ...this.$store.state.clients.client ]
        },
        localActiveSidebar: {
            get: function() {
                return this.sidebar;
            },
            set: function( val ) {                
                this.edit = true
                this.$emit( 'closeSidebar', val );
                this.$store.dispatch( 'clients/getAllClients' );
            }
        },

        user: {
            cache: true,
            get: function() {
                /* let ob = []
                this.data.contacts.map(sign => {
                    ob.push(sign.email);
                });
                this.data['contacts_total'] = ob; */
                return this.data;
            },
            set( val ) {
                this.$store.commit('clients/SET_CLIENT', val );
            }
            
        },
        callsigns() {
            const callsigns = this.$store.state.location.callsigns;
            const signs = callsigns.map(sign => {
                let ob = Object.assign({}, sign);
                ob.sign = `+${sign.callingCodes[0]}`;
                return ob;
            });

            return signs;
        },
        superAdminAccess() {
            return AuthService.isValidModule( 'OWNER' );
        },
        apps() {
            return this.$store.state.applications.apps;
        },
        userStatus() {
            return this.$store.state.users.userStatus;
        },

    },

    components: {
        VuePerfectScrollbar,
        vSelect,
    }
});
</script>

<style lang="scss" scoped>
.user-sidebar {
    ::v-deep .vs-sidebar--background {
        z-index: 52010;
    }
    
    ::v-deep .vs-sidebar {
        position: fixed;
        z-index: 52010;
        width: 470px;
        max-width: 90vw;
        .vs-sidebar--header {
            background: #841910;
            color: #FFF;
        }
    }
}

.vx-input-group{
    width:100% !important;
    min-width:100% !important;
}

.vs-con-input .vs-inputx {
  background-color: white !important;
  color: black  !important;
  font-weight: 500;
}
.scroll-area--data-list-add-new {
    height: 79vh;
}
.mt-2 {
  margin-top:10px !important;
  margin-bottom:10px !important;
}
</style>

<template>
  
  <div>
    <div class="row mt-2">
      <div class="col-md-12 flex justify-between">
        <vs-button
            class="rounded-full text-white px-8"
            color="primary"
            @click="activeDeleteDialog()"
        >
            <feather-icon icon="RefreshCwIcon" class="mr-2 inline-block align-middle"></feather-icon>
            {{ this.$t('Actualizar Clientes') }}
        </vs-button>
        <div class="inline-block row " style="vertical-align: middle; margin-left: 25px !important;">
          <div class="col-1 inline-block">
            <span>{{ this.$t('Buscar') }}: </span>
          </div>
          <div class="col-4 inline-block" style="vertical-align: middle;">
            <div class="border-2 h-12 border-solid rounded-full" style="background-color:white; border-radius: 9px;">
              <div class="flex h-full">
                  <feather-icon :icon="'SearchIcon'" class="mt-2 mb-2 ml-2" />
                  <input
                      @keyup.enter="findClients(false)"
                      class="h-full w-full border border-none mr-5"
                      ref="users-search"
                      v-model="pagination.name"
                  />
                </div>
            </div>
          </div>
          <div class="col-1 inline-block" style="margin-left: 25px !important;">
            <span>{{ this.$t('Ver') }}: </span>
          </div>
          <div class="col-4 inline-block" style="vertical-align: middle;">
            <div class="flex justify-end w-full">
              <!-- eslint-disable -->
              <vs-select v-model="pagination.page_size" class="w-32 inline-block">
                <vs-select-item :key="index" :value="item" :text="item" v-for="item,index in maxItemsVals" />
              </vs-select>
            </div>
            
          </div>
          <div class="col-2 inline-block" style="margin-left: 25px !important;">
            <vs-button :disabled="download_data.length == 0"
              style="background-color:#841910 !important; border-radius:10px"
              class=""
              @click="exportData"
            >
                <feather-icon icon="DownloadIcon" class="align-middle"></feather-icon>
            </vs-button>
          </div>
          
        </div>
        
      </div>
    </div>
    <div class="card card_total">
      <div class="card-body">
        <div class="row flex pt-4 px-4">
          <div class="col-md-5" style="width:40%">
            <div class="row flex justify-between text-center">
              <div class="col-sm-3" style="padding-left:20px;">Total clientes<h3>{{statistics.total_clients}}</h3></div>
              <div class="col-sm-3 v-line">Activos<h3 style="color:#02c102;">{{statistics.active}}</h3></div>
              <div class="col-sm-3 v-line">Inactivos<h3 style="color:#841910;">{{statistics.inactive}}</h3></div>
              <div class="col-sm-3 v-line">Positivados<h3>{{statistics.positive}}</h3></div>
            </div>
          </div>
          <div class="col-md-7 flex mt-3" style="width:60%; justify-content: end;">
             <p class="mt-1">Fecha última actualizacion:</p>&nbsp;&nbsp;<h3>{{statistics.date_update}}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-10">
      <vs-table :max-items="pagination.page_size" :data="all" :noDataText="this.$t( 'Datos no disponibles' ) ">
        <!-- encabezado de tabla -->
        <template slot="thead">
          <vs-th class="text-info bgf">{{ this.$t('ID. Cliente') }}</vs-th>
          <vs-th class="text-info bgf" style="max-width:240px">{{ this.$t('Nombre') }}</vs-th>
          <vs-th class="text-info bgf" style="max-width:240px">{{ this.$t('Direccion') }}</vs-th>
          <vs-th class="text-info bgf">{{ this.$t('G. Precios') }}</vs-th>
          <vs-th class="text-info bgf">{{ this.$t('Industrial') }}</vs-th>
          <vs-th class="text-info bgf">{{ this.$t('Metodo Pago P.') }}</vs-th>
          <vs-th class="text-info bgf">{{ this.$t('Metodos Pagos') }}</vs-th>
          <vs-th class="text-info bgf" style="min-width:180px">{{ this.$t('Acciones') }}</vs-th>
        </template>
        <!-- cuerpo de tabla -->
        <template slot-scope="{data}">
          <template>
            <div style="min-height:20px"></div>
          </template>
          
          <vs-tr :data="tr" class="tr zoom" v-bind:key="indextr" v-for="(tr, indextr) in all">
            <!-- identification -->
            <vs-td :data="data[indextr].id">
              <span>{{ data[indextr].id }}</span>
            </vs-td>

            <!-- nombre -->
            <vs-td :data="data[indextr].name" style="max-width:240px">
              <span>{{ data[indextr].name }}</span>
            </vs-td>

            <!-- email -->
            <vs-td :data="data[indextr].addresses" style="max-width:240px">
              <span>{{ data[indextr].addresses[0].address }}</span>
            </vs-td>

            <!-- telefono -->
            <vs-td :data="data[indextr].price_group">
              <span>{{ data[indextr].price_group }}</span>
            </vs-td>

            <vs-td :data="data[indextr].seleciona_entrega">
              <span>{{ data[indextr].seleciona_entrega }}</span>
            </vs-td>

            <vs-td :data="data[indextr].default_payment_option">
              <span>{{ data[indextr].default_payment_option.payment_method_name }}</span>
            </vs-td>

            <vs-td :data="data[indextr].payment_method_groups" style="text-align: center;">
              <span>{{ data[indextr].payment_method_groups.length }}</span>
            </vs-td>
            
            <!-- Editar, eliminar, guardar -->
            <vs-td :data="data[indextr].id" style="min-width:180px">
              <vs-button style="padding: 0.7rem 0.7rem;" color="transparent" @click="viewClients( data[indextr].id )">
                  <feather-icon icon="InfoIcon" style="color:black !important" class="inline-block align-middle"></feather-icon>
              </vs-button>
              <div class="inline-flex">
                <div v-if="data[indextr].status == 'A'"
                    style="background: #841910; color: #ffffff;"
                    class="w-24 p-1 mb-1 rounded-lg text-center"
                  >
                    {{ $t('Activo') }}
                </div>
                <div v-if="data[indextr].status != 'A'"
                    style="background: #dee2e5; color: black;"
                    class="w-24 p-1 mb-1 rounded-lg text-center"
                >
                    {{ $t('Inactivo') }}
                </div>
              </div>
                
            </vs-td>

          </vs-tr>
          <div style="margin-top:20px"> {{ $t('mostrando') }} {{ data.length }} {{ $t('de') }} {{ total }}</div>
        </template>
      </vs-table>
      <vs-pagination :total="pagination.total" class="mt-3" v-model="pagination.current"></vs-pagination>
        
      <!-- modal eliminar -->
        <vs-prompt
            @accept="toggleRoute()"
            @close="closeDeleteDialog()"
            :active.sync="activePrompt"
            :title="this.$t('Actualizar clientes')"
            buttons-hidden
        >
            <div class="w-full">
                <vs-button  class="float-right" @click.native="closeDeleteDialog()" color="transparent" text-color="#666">
                    <feather-icon icon='XIcon'  />
                </vs-button>
            </div>
            
            <div class="w-full flex justify-center mb-5">
                <img :src="require(`@/assets/images/question.png`)" alt="Icon" width="168px" />
            </div>
            
            <div class="text-center text-gray-400">
                <p>
                    <h5>{{ this.$t('¿Estás seguro que deseas ejecutar la actualizacion de clientes?') }}</h5>
                    <span>{{ this.$t('Recuerda que esta accion puede generar inconvenientes en las compras') }}&nbsp;</span>
                </p>
                <p><vs-button style="border: #e32113 solid 2px" class="mt-5 rounded-full mr-5" @click="toggleRoute()">{{ this.$t('Ejecutar') }}</vs-button>
                <vs-button style="border: #e32113 solid 2px" class="mt-2 ml-5 rounded-full text-primary" @click.native="closeDeleteDialog()" color="#FFF">{{ this.$t('Cancelar') }}</vs-button></p>
            </div>
        </vs-prompt>
    </div>
    <EditUserSidebar :sidebar="sidebar" :data="userSelected" @closeSidebar="closeSidebar"/>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import FeatherIcon from '../../../components/FeatherIcon.vue';
import EditUserSidebar from './EditUserSidebar.vue';
import vSelect         from 'vue-select';

export default Vue.extend({
  components: {
    'v-select': vSelect,
    FeatherIcon,
    EditUserSidebar,
  },
  data() {
        return {
            all             : [],
            selected     : [],
            activePrompt : false,
            loading      : false,
            pagination   : { total: 1, current: 1, page_size: 20, name: '', id: 0 },
            timeout      : true,
            sidebar         : false,
            userSelected    : { id : 0 },
            maxItems        : 20,
            maxItemsVals    : [5,10,15,20,25,30,35,40,45,50],
            dat          : [],
            switc       : false,
            total       : 0,
            newData: [],
            download_data    : [],
            statistics  : { total_clients: 0, positive: 0, active: 0, inactive: 0, date_update: '0000-00-00 00:00:00'}
        }
    },

    created() {
      this.$vs.loading();
      this.findClients(false);
      this.$vs.loading.close();

    },

    watch: {
      'pagination.current'() { this.findClients(false); },
      'pagination.page_size'() { this.findClients(false); }
    },

    methods: {
        
        activeDeleteDialog() {
            this.activePrompt = true;
        },

        closeDeleteDialog() {
            this.selected = [];
            this.activePrompt = false;
        },
        viewClients( id=0 ) {
          this.userSelected = this.dat.find( user => user.id === id );
          this.sidebar      = true;
        },
        closeSidebar( val ) {
          this.sidebar      = val;
          //this.userSelected = { id : 0 };
          this.findClients(false);
        },
        async toggleRoute() {
          this.$vs.loading();

          const res = await this.$store.dispatch('clients/updateCLientsForTaskService');
          
          if (res) {
            this.$vs.loading.close();
            this.activePrompt = false;
            this.$vs.notify({
              title: this.$t('El proceso ha comenzado a ejecutarse'),
              text: this.$t('Los clientes se actualizarán y estarán disponibles en unos minutos'),
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            });
          } 
        },
        async findClients(val) {
            this.loading = true
            const payload = {
                page      : this.pagination.current,
                page_size : this.pagination.page_size,
                search    : this.pagination.name
            };
            if (val){
              payload.page_size = 1000
            }
            const results = await this.$store.dispatch('clients/getAllClients', payload);

            if( !results ) {
              this.loading = false;
              this.$vs.loading.close();
            } else {
                this.loading = false;
                this.$vs.loading.close();
                if (val){
                  this.download_data = results.data.results
                } else {
                  this.all = results.data.results
                  this.dat = results.data.results
                  this.total = results.data.total
                  this.statistics = results.data.statistics
                  this.pagination.total = results.data.total_pages-1;
                  if (this.timeout){
                    this.timeout = false;
                    this.findClients(true);
                  }
                }
            }

            
        },

        exportData() {
          let data = [];            

          this.$vs.loading();

          if( this.all.length === 0 ) {
            this.$vs.notify({
                title: this.$t('No hay datos en esta consulta'),
                text: '',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning'
            });
            return;
          }

          data = this.mapData( this.download_data );

          const headerVal   = [
              "identification",
              "name",
              "address",
              "g_precios",
              "m_pagos",
              "contact",
              "industrial",
              "status",
              "consumers"
          ];
          const headerTitle = [
            this.$t('ID. Cliente'),
            this.$t('Nombre'),
            this.$t('Direccion'),
            this.$t('G. Precios'),
            this.$t('Metodos Pagos'),
            this.$t('Datos de contacto'),
            this.$t('Industrial'),
            this.$t('Estado'),
            this.$t('Consumidores asociado')
          ];
          const cellAutoWidth = true;
          const self = this;
          
          self.exportData = data;
          
          import('../../../vendor/Export2Excel').then( excel => {
            const list = self.exportData;
            this.$vs.loading.close();
        
            const data = this.formatJson( headerVal, list );

            excel.export_json_to_excel({
                header: headerTitle,
                data,
                filename: "clients",
                autoWidth: cellAutoWidth,
                bookType: "xlsx",
              });
                
            });
        },
        mapData( data=[] ) {            
          
          data.forEach( key => {
            let state = this.$t('Activo')
            if( key.status != 'A' ){ 
              state = this.$t('Inactivo');}
            this.newData.push({
              identification: key.id,
              name: key.name,
              address: key.addresses[0].address,
              g_precios: key.price_group,
              m_pagos: key.payment_method_groups.length,
              users: key.contacts[0].email,
              industrial: key.seleciona_entrega,
              status: state,
              consumers: key.cunsumers_email.join(', ')
            });
          });
          
          return this.newData;
            
        },

        formatJson( filterVal=[], jsonData=[] ) {
            return jsonData.map( v => filterVal.map( j => {
                return v[j];
            }) );
        }
    },

    computed: {
    },
});
</script>
<style lang="scss">
.vs-dialog-header {
    display: none !important;
}

.bgf{
  background-color: #F3F2F7!important;
  color: #636363 !important;
}

th.td-check {
  padding: 0!important;
  background-color: #F3F2F7!important;
}

.card_total{
  margin-top:30px;
  background-color:white;
  height:70px;
  border: 0.2px black;
  border-radius: 15px !important;
  box-shadow: 1px 3px 3px 3px rgba(34, 41, 47, 0.164);
}

.v-line{
  //border-left: thick solid #000;
  border-left: solid #000;
  height:100%;
  padding-left: 20px;
}
</style>